<template>
  <main class="bg-white">
    <section class="core">
      <div class="order">
        <div class="model-title row-between">
          <div class="column">
            <div class="title lg normal mb10">确认采购清单</div>
          </div>
        </div>
        <div class="order-box lighter nr">
          <div class="order-title row">
            <div class="item-title flex2"><span>商品</span></div>
            <div class="item-title flex1" >单价</div>
            <div class="item-title flex1">数量</div>
            <!-- <div class="item-title flex1">期望到货时间(非必填)</div> -->
            <div class="item-title flex1">小计</div>
          </div>
          <div class="order-list" v-for="(item, index) in cartInfo.shop" :key="index">
            <!-- <div class="bg-white list-title xs">{{ item.shop_name }}</div> -->
            <div class="order-item row normal nr" v-for="(itemTwo, indexTwo) in item.goods" :key="indexTwo">
              <div class="item-pro flex2 row">
                <el-image class="img mr20 ml30" :src="itemTwo.image" fit="cover"></el-image>
                <div class="pro-title line2">
                  {{ itemTwo.name }}
                </div>
              </div>
              <div class="item-price flex1 row-center">
                <make-priceformat :price="itemTwo.price" :subscriptSize="17" :firstSize="17" :secondSize="17"
                  color="#777777"></make-priceformat>
              </div>
              <div class="item-num flex1 row-center black">
                {{ itemTwo.num }}
              </div>
              <!-- <div class="item-time flex1 row-center normal">
                <div class="block">

                  <!-- <el-input placeholder="期望到货时间" v-model="itemTwo.goods_id" clearable>
                  </el-input> -->
                  <!-- <el-date-picker v-model="itemTwo.goods_amount" type="datetime" placeholder="选择日期时间"
                    @change="onchangeDate($event, itemTwo.goods_id)">
                  </el-date-picker> -->

                <!-- </div>
              </div> - -->
              <div class="item-count flex1 row-center">
                <make-priceformat :price="itemTwo.sum_price" :subscriptSize="13" :firstSize="20" :secondSize="20"
                  color="#C91623"></make-priceformat>
              </div>
            </div>
            <div class="item-count nr nr normal row-end">
              运费：
              <make-priceformat :price="item.shipping_price" :subscriptSize="16" :firstSize="16" :secondSize="16"
                color="#333"></make-priceformat>
              &nbsp; &nbsp; 合计：
              <make-priceformat :price="item.total_amount" :subscriptSize="16" :firstSize="16" :secondSize="16"
                color="#333" ></make-priceformat>             
                
                <el-input type="text"  v-show="false" v-model="item.total_amount">
          </el-input>  


            </div>            
          </div>          
        </div>
        <br />
        <div class="model-title row-between">
          <div class="column">
            <div class="title lg normal mb10">采购方案名称</div>
          </div>
        </div>
        <div class="row-between remark">
          <el-input type="text" style="width: 500px" resize="none" :autosize="{ minRows: 4, maxRows: 8 }"
            placeholder="请输入采购方案名称" v-model="plan_name">
          </el-input>     
        </div>

        <div class="model-title row-between">
          <div class="column">
            <div class="title lg normal mb10">采购备注</div>
          </div>
        </div>
        <div class="row-between remark">
          <el-input type="textarea" style="width: 500px" resize="none" :autosize="{ minRows: 4, maxRows: 8 }"
            placeholder="请输入备注信息" v-model="remark">
          </el-input>
          <div class="order-info">
            <p class="lighter sm mb10">
              {{ cart_data.length || 0 }}件商品，总商品金额：
              <make-priceformat :price="cartInfo.goods_amount" :subscriptSize="15" :firstSize="15" :secondSize="15"
                color="#666666"></make-priceformat>
            </p>

            <div class="operate row">
              <router-link to="/shoppingPlan">
                <div class="operate-back row lg lighter">
                  <img src="@/assets/icon/icon-back.png" alt="" class="mr10" />
                  返回采购清单
                </div>
              </router-link>
              <button type="primary" class="white lg row-center" @click="submitProcure">
                提交采购计划
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>
</template>
<script>
import AddAddress from "../../user/center/components/AddAddress.vue";
export default {
  name: "",
  props: {},
  components: {
    "add-address": AddAddress,
  },
  data() {
    return {
      urgent: false,
      dialogVisible: false,
      addressSelect: {},
      cartInfo: {}, //选择的商品
      addressList: [], //地址列表
      addressShow: false, //地址是否展示
      addressType: "add", //地址添加编辑类型 add 添加 edit 编辑
      addressId: null, //当前编辑的地址ID
      totalShipping: 0, //当前总运费
      cart_id: "", //购物车ID
      cart_data: "", //购物车列表
      remark: "",
      plan_name: "",
      total_amount:0,
      active_type: 0,
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      value1: '',
      value2: '',
      value3: '',
      desireDate: [],//期望到货日期
      date: "",




    };

  },
  computed: {},
  watch: {},
  created() {
    this.newDate();
  },
  methods: {
    newDate(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    onchangeDate(event, goods_id) {
      if (event != null) {
        this.date = this.newDate(event);
        let arrA = [goods_id, this.date];
        this.desireDate.push(arrA);
      } else {
        for (const [value0, value1] of this.desireDate) {
          if (value0 == goods_id) {           
            this.desireDate.splice([value0, value1], 1);
          }     
        }    
      }
   
    },

    /**
     * @description 格式化手机
     * @param {String | Number} val
     * @return 138-0000-0000
     */
    formatPhone(val) {
      if (val) {
        let matches = /^(\d{3})(\d{4})(\d{4})$/.exec(val);
        if (matches) {
          val = matches[1] + "-" + matches[2] + "-" + matches[3];
        }
      }
      return val;
    },


    /**
     * @desciption 方案提交 -- step 1
     * @return void
     */
    async submitProcure() {    

   
 
      let { addressSelect, cart_id, cart_data: goods } = this;
      let params = {
        address_id: addressSelect.id,
        active_type: this.active_type,
        cart_id,
        goods: JSON.stringify(goods),
        distribution_type: 0,
        plan_name: this.plan_name,
        remark: this.remark,
        urgent: this.urgent,
        total_amount: this.total_amount,
        desireDate: JSON.stringify(this.desireDate)
      };

      console.log(params);
      let { code, data, msg } = await this.$api.submitCreatedProcureApi(params);
      if (code == 1) {
        this.$message({
          message: msg,
          type: "success",
        });
        this.$router.push({
          name: "shoppingPlanList",
        });
      }
    },
    /**
     * @description 设置结算单
     * @returns void
     */
    async SetTlement() {
      let { code, data, value1 } = await this.$api.setTlementApi({
        cart_id: this.cart_id,
        goods: JSON.stringify(this.cart_data),
        value1: this.value1,
      });
      if (code == 1) {
        this.cartInfo = data;
        data.shop.forEach((el) => {
          this.totalShipping += el.shipping_price;
        });
      }
    },
  },
  async created() {
    let { cart_id, data, addressId, active_type } = JSON.parse(
      decodeURIComponent(this.$route.query.data)
    );
    this.cart_id = cart_id;
    this.cart_data = data;
    this.active_type = active_type || 0;
    await this.SetTlement();
    this.getAddressList(addressId);
  },
  mounted() {


  },
  beforeDestroy() { },
};
</script>
<style lang="scss" scoped>
section {
  padding: 40px 0;

  .model-title {
    border-bottom: $-solid-border;
    margin-bottom: 20px;
  }
}

.form-box {
  .flow-box {
    .flow-item {
      width: 160px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        top: 8px;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: $-color-primary;
      }

      &.op {
        opacity: 0.3;
      }

      &.gray {

        .item-num,
        &::before {
          background-color: #cccccc !important;
        }

        .item-title {
          color: #cccccc;
        }
      }

      .item-num {
        position: relative;
        z-index: 2;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $-color-primary;
        line-height: 20px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }

  .consignee {
    border-bottom: $-solid-border;

    .add {
      cursor: pointer;
    }

    .address-list {
      .address-item {
        height: 34px;

        &:hover {
          background: #f7f7f7;

          .item-name {
            border: 2px solid $-color-primary;
            color: $-color-primary;
            background-color: white;
          }

          .info-btn {
            display: block;
          }
        }

        &.active {
          .item-name {
            border: 2px solid $-color-primary;
            color: $-color-primary;
            background-color: white;
          }

          .info-text {
            color: $-color-primary;
          }
        }

        .item-name {
          width: 110px;
          border: 2px solid $-color-border;
          padding: 0 10px;
          flex-shrink: 0;
          height: inherit;
        }

        .item-info {
          height: inherit;
          padding-left: 20px;
          padding-right: 56px;
          letter-spacing: 1px;
        }

        .info-btn {
          display: none;

          span {
            margin-right: 30px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .retract {
      img {
        transform: rotate(180deg);
      }

      &.active {
        img {
          transform: rotate(0);
        }
      }

      img {
        width: 12px;
        margin-left: 10px;
      }
    }
  }
}

.remark {
  align-items: flex-start;
}

.order {
  .order-box {
    background: #f7f7f7;
    margin-bottom: 50px;

    .order-title {
      height: 52px;
      line-height: 52px;
      border-bottom: $-solid-border;

      span {
        padding-left: 186px;
      }

      .item-title {
        flex-shrink: 0;
      }

      .flex1 {
        text-align: center;
      }
    }

    .order-list {
      .list-title {
        padding: 15px 10px 10px;
      }

      .order-item {
        padding: 25px 0;
        border-bottom: $-solid-border;

        .item-pro {
          .img {
            width: 64px;
            height: 64px;
          }

          .pro-title {
            width: 296px;
          }
        }
      }

      .item-count {
        padding: 10px;
      }
    }
  }

  .order-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;

    .info-detail {
      margin-top: 35px;
    }
  }

  .operate {
    .operate-back {
      margin-right: 26px;
      cursor: pointer;

      img {
        width: 22px;
      }
    }

    button {
      cursor: pointer;
      width: 183px;
      height: 51px;
    }
  }
}

/deep/ .address-dialog {
  .el-dialog__header {
    padding: 30px;
  }

  .el-dialog__body {
    padding: 10px 147px 60px 0;

    .add-title {
      width: 587px;
      height: 42px;
      margin-left: 160px;
      padding-left: 15px;
    }
  }
}
</style>
